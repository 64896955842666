import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-toastify'
import i18n from '../../i18n'

import { getOrder, resetOrder } from './orderSlice'
import { resetMenuItems } from './menuItemsSlice'
import { resetRestaurants } from './restaurantsSlice'

const initialState = {
  /* info holds the related user data, their username, name, and contact info. */
  info: {
    username: 'John Duo',
    firstName: 'John',
    lastName: 'Duo',
    email: 'johnduo@gmail.com',
    phone: '+358 457 398 957'
  },
  /* userTime holds the user's chosen time for an order method. */
  userTime: '',
  /* creditCardInfo holds all the related info about the user's credit card. */
  creditCardInfo: null,
  /* orders holds the whole purchase order of the user. */
  orders: [],
  /* total holds the user's final bill including VAT and fees. */
  total: 0,
  /* userAddress holds all the user's related location info. */
  userAddress: null,
  /* orderMethod holds the method that the user chose for their purchase order. */
  orderMethod: '',
  /* ongoingOrders holds all the ongoing orders made by the user and paid for. */
  ongoingOrders: [],
  /* deliveredOrders holds all the delivered orders after removing them from ongoingOrders. */
  deliveredOrders: [],
  /* selectedOrder holds the currently selected order in ongoing orders to display its bill details */
  selectedOrder: '',
  /* currentBillDetails holds the bill details of the currently selected order to display that info correctly. */
  currentBillDetails: null,
  /* notifications holds all user notifications regarding their orders. */
  notifications: [],
  /* responeData holds the response from Paytrail when payment is being made, in case some other info is needed. */
  responseData: null,
  /* paymentUrl holds the href returned from Paytrail to lead the user to the payment methods. */
  paymentUrl: '',
  /* orderStatusChanged is a flag needed for some rendering context and making requests to change order status. */
  orderStatusChanged: false,
  /* isProcessing is a flag used used for spinner on buttons indicating an action is being done. */
  isProcessing: false,
  /* isChangingStatus is a flag used to indicate the the order status is being changed. */
  isChangingStatus: false,
  // If completeTransaction success but get error when changeOrderStatus
  changeOrderStatusError: false,
  ordersOngoing: [],
  ordersDelivered: [],
  ordersHistory: [],
  orderHistoryStatus: 'idle'
}

/* setAllUserData pieces together all the related info of the user, and group them all in one place for POST. */
export const setAllUserData = createAsyncThunk(
  'userInfo/setAllData',
  async (creditCardInfo, { getState, dispatch }) => {
    return {
      creditCardInfo: creditCardInfo,
      orders: JSON.parse(JSON.stringify(getState().order.orders)),
      billDetails: {
        total: getState().order.total,
        fees: [...getState().order.fees]
      },
      userAddress: { ...getState().restaurantsDetails.userAddress },
      orderMethod: getState().showModal.selectedOrderMethodId,
      restaurantInfo: {
        ...getState().restaurantsDetails.selectedRestaurantInfo
      },
      dispatch: dispatch
    }
  }
)

// getOrdersHistory
export const getOrdersHistory = createAsyncThunk(
  'userInfo/getOrdersHistory',
  async (_, { getState, rejectWithValue }) => {
    const lang = getState().lang.lang
    const token = JSON.parse(localStorage.getItem('user')).token
    try {
      const { data } = await axios.get(process.env.REACT_APP_API + 'user/orderHistory?with_pagination=no', {
        headers: {
          'X-localization': lang,
          Authorization: `Bearer ${token}`
        }
      })
      return data.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

const prepareOrders = (orders, tax, merchant_id, orderMethod, deliveryFee, drinksData) => {
  const preparedOrders = orders.map(({ item_with_sub_items_price, quantity, item_id }) => {
    return {
      // unitPrice: Math.round(
      //   ((item_with_sub_items_price +
      //     (item_with_sub_items_price * tax) / 100) *
      //     100) /
      //     quantity
      // ),
      unitPrice: Math.round((item_with_sub_items_price * 100) / quantity),
      units: quantity,
      vatPercentage: tax,
      productCode: `#${item_id}`,
      reference: String(item_id),
      // merchant: merchant_id, // ONLY USE THIS WHEN MERCHANT_ID IS AVAILABLE IN GET RESTAURANT
      // merchant: '1069569',
      // MAKE SURE TO REMOVE THIS AND ONLY USE MERCHANT_ID ONCE IT'S AVAILABLE IN GET RESTAURANT
      // merchant: merchant_id ? merchant_id : "1069569",
      merchant: merchant_id
    }
  })

  drinksData?.map(({ price, count, id }) => {
    preparedOrders.push({
      unitPrice: Math.round(
        // price * count * 100 + (price * count * 100 * tax) / 100
        price * count * 100
      ),
      units: count,
      vatPercentage: tax,
      productCode: `#drink${id}`,
      reference: String(`#drink${id}`),
      merchant: merchant_id
    })
    return null
  })
  if (orderMethod.toLowerCase() === 'delivery') {
    preparedOrders.push({
      unitPrice: Math.round(deliveryFee * 100),
      units: 1,
      vatPercentage: 0,
      productCode: '#delivery',
      reference: 'delivery',
      // MAKE SURE TO REMOVE THIS AND ONLY USE MERCHANT_ID ONCE IT'S AVAILABLE IN GET RESTAURANT
      merchant: merchant_id
    })
  }

  return preparedOrders
}

const prepareTransaction = state => {
  const items = prepareOrders(
    state.orders,
    state.tax,
    state.merchant_id,
    state.orderMethod,
    state.deliveryFee,
    state.drinksData
  )

  const transaction = {
    reference: String(state.orders[0].id),
    amount: Math.round(+state.total * 100),
    currency: 'EUR',
    language: 'FI',
    coupon: state.coupon?.code,
    order_ids: state.orders?.map(el => el?.id),
    customer: [{ email: state.email }],
    commission: {
      merchant: state.merchant_id,
      amount: state.commission ? state.commission : 10
    },
    items: items,
    // the domain is stored in env, and can be changed to the latest main website link.
    redirectUrls: [
      {
        success: `${process.env.REACT_APP_DOMAIN}my-orders`,
        cancel: `${process.env.REACT_APP_DOMAIN}cart`
      }
    ]
  }

  return transaction
}

// This is the real code DON'T DELETE
export const completeTransaction = createAsyncThunk(
  'userInfo/completeTransaction',
  async (param, { getState, rejectWithValue }) => {
    const state = {
      orders: getState().order.orders,
      email: getState().signIn.email,
      total: getState().order.total,
      tax: getState().order.taxPercentage,
      merchant_id: getState().restaurantsDetails.selectedRestaurantInfo.merchant_id,
      commission: getState().restaurantsDetails.selectedRestaurantInfo.commission,
      orderMethod: getState().showModal.selectedOrderMethodId,
      deliveryFee: getState().order.deliveryFee,
      drinksData: getState().order.drinksData,
      coupon: getState().coupon.couponData
    }
    const transaction = prepareTransaction(state)

    try {
      const response = await axios.post(`${process.env.REACT_APP_API}user/placeOrder`, transaction)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response)
    }
  }
)

export const changeOrderStatus = createAsyncThunk(
  'userInfo/changeOrderStatus',
  async (status, { dispatch, rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem('user')).token

    try {
      const response = await axios.post(`${process.env.REACT_APP_API}restaurants/order/status?status=${status}`, null, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (response.status === 200) {
        // dispatch(setSelectedOrderMethod(""));
        dispatch(resetOrder())
        dispatch(resetMenuItems())
        dispatch(resetRestaurants())
        dispatch(getOrder())
      }

      return response.data
    } catch (error) {
      return rejectWithValue(error.response.message)
    }
  }
)

const generateRandomOrderNumber = () => {
  const min = 111111
  const max = 999999
  const result = Math.floor(Math.random() * (max - min + 1) + min)
  return result
}

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    /* setUserContactInfo fills the user's contact info with the data they provided.
        action.payload includes first name, last name, email, and phone number, while username is derived from
        first and last name. */
    setUserContactInfo: (state, action) => {
      // state.info.email = action.payload.email;
      // state.info.phone = action.payload.phone;
      state.info = {
        ...state.info,
        ...action.payload,
        username: `${action.payload.firstName} ${action.payload.lastName}`
      }
    },

    /* setUserTime stores the time that the user chose for their order method. */
    setUserTime: (state, action) => {
      state.userTime = action.payload
    },

    /* setSelectedOrder changes whether an ongoing order is selected or not, so bill details are rendered properly. */
    setSelectedOrder: (state, action) => {
      let orderIndex = null
      if (action.payload.selected) {
        state.selectedOrder = action.payload.orderNumber
        if (action.payload.status === 'ongoing') {
          orderIndex = state.ongoingOrders.findIndex(({ orderNumber }) => orderNumber === action.payload.orderNumber)
          state.currentBillDetails = {
            ...state.ongoingOrders[orderIndex].billDetails
          }
        } else {
          orderIndex = state.deliveredOrders.findIndex(({ orderNumber }) => orderNumber === action.payload.orderNumber)
          state.currentBillDetails = {
            ...state.deliveredOrders[orderIndex].billDetails
          }
        }
      } else {
        state.selectedOrder = ''
        state.currentBillDetails = null
      }
    },

    /* sendNotification notifies the user when the order is being worked on, and when it's delivered. */
    sendNotification: state => {
      const deliveredOrder = state.ongoingOrders.shift()
      state.deliveredOrders = [...state.deliveredOrders, deliveredOrder]
      state.selectedOrder = ''
      state.currentBillDetails = null
      const newNotification = {
        id: `${state.deliveredOrders.slice(-1)[0].orderNumber}-${state.notifications.length}`,
        title: `${state.deliveredOrders.slice(-1)[0].orderNumber} - ${
          state.deliveredOrders.slice(-1)[0].restaurantInfo.name
        }`,
        content: 'Your order has been delivered! Thank you for using our service. We hope you enjoy your meal!',
        time: state.deliveredOrders.slice(-1)[0].readyTime
      }
      state.notifications = [...state.notifications, newNotification]
      // setTimeout(() => {
      // }, 60000);
    },

    /* setNotificationUnread changes the read state of the notification when the user reads it. */
    setNotificationUnread: (state, action) => {
      state.notifications = state.notifications.filter(({ id }) => id !== action.payload)
    },

    setOrderStatusChanged: (state, action) => {
      state.orderStatusChanged = action.payload
    }
  },
  extraReducers: builder => {
    /* this case groups together all the related info of the user so that they can be sent to the server later. */
    builder
      .addCase(setAllUserData.fulfilled, (state, action) => {
        state.creditCardInfo = action.payload.creditCardInfo
        const ongoingOrder = {
          orderNumber: `#${generateRandomOrderNumber()}`,
          orders: action.payload.orders,
          orderMethod: action.payload.orderMethod,
          readyTime: state.userTime,
          billDetails: action.payload.billDetails,
          restaurantInfo: action.payload.restaurantInfo
        }
        state.ongoingOrders = [...state.ongoingOrders, ongoingOrder]
        const newNotification = {
          id: `${ongoingOrder.orderNumber}-${state.notifications.length}`,
          title: `${ongoingOrder.orderNumber} - ${ongoingOrder.restaurantInfo.name}`,
          content:
            'Order received! Your food is being prepared with love and attention. Hang tight, it will be delivered to you shortly. Enjoy your meal!',
          time: ongoingOrder.readyTime,
          isUnread: true
        }
        state.notifications = [...state.notifications, newNotification]

        setTimeout(() => {
          action.payload.dispatch(sendNotification())
        }, 60000)

        // state.creditCardInfo = action.payload.creditCardInfo;
        // state.orders = action.payload.orders;
        // state.total = action.payload.total;
        // state.userAddress = action.payload.userAddress;
        // state.orderMethod = action.payload.orderMethod;
        // state.restaurantInfo = action.payload.restaurantInfo;
        // console.log(`
        // User Info: ${state.info},
        // User Address: ${state.userAddress.address},
        // Credit Card Info: ${state.creditCardInfo},
        // Selected Time: ${state.userTime},
        // Selected Order Method: ${state.orderMethod},
        // Total Bill: ${state.total},
        // Orders: ${state.orders}
        // `);
      })

      .addCase(completeTransaction.fulfilled, (state, action) => {
        state.responseData = action.payload
        // console.log('responseData: ', state.responseData, typeof(state.responseData));
        state.paymentUrl = state.responseData.href
        state.isProcessing = false
      })
      .addCase(completeTransaction.pending, state => {
        state.isProcessing = true
      })
      .addCase(completeTransaction.rejected, (state, action) => {
        state.isProcessing = false
        toast.error(i18n.t('Something went wrong, please try again'))
      })

      .addCase(changeOrderStatus.fulfilled, (state, action) => {
        state.orderStatusChanged = true
        state.isChangingStatus = false
        state.changeOrderStatusError = false
      })
      .addCase(changeOrderStatus.pending, (state, action) => {
        state.isChangingStatus = true
        state.changeOrderStatusError = false
      })
      .addCase(changeOrderStatus.rejected, (state, action) => {
        state.orderStatusChanged = false
        state.isChangingStatus = false
        state.changeOrderStatusError = true
        toast.error(i18n.t('Something went wrong, please try again'))
      })
      .addCase(getOrdersHistory.fulfilled, (state, action) => {
        state.ordersHistory = action.payload
        state.orderHistoryStatus = 'succeeded'
      })
      .addCase(getOrdersHistory.pending, (state, action) => {
        state.orderHistoryStatus = 'loading'
      })
      .addCase(getOrdersHistory.rejected, (state, action) => {
        state.orderHistoryStatus = 'rejected'
      })
  }
})

export const {
  setUserContactInfo,
  setUserTime,
  setSelectedOrder,
  sendNotification,
  setNotificationUnread,
  setOrderStatusChanged
} = userInfoSlice.actions

export default userInfoSlice.reducer
