import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { setSelectedItemId } from "./menuItemsSlice";
import { setSelectedRestaurantId, setUserAddress } from "./restaurantsSlice";
import { setSelectedOrderMethod, hideModal } from "./showModalSlice";
import { setInCheckout } from "./checkoutSlice";

import axios from "axios";
import i18n from "../../i18n";

import { toast } from "react-toastify";

import { setUserTime } from "./userInfoSlice";
import { max } from "lodash-es";

const url = process.env.REACT_APP_API;

const initialState = {
  /* bill stores the current item's total price calculated based on user's choices. */
  bill: 0,
  billWithVat: 0,
  /* minBill is the minimum purchase order according to the Finnish laws. */
  minBill: 14,
  /* orderCount holds the count of the same item that the user wants. */
  orderCount: 1,
  /* lastSelectedSizePrice stores the price of the last selected item size, useful for some calculations.  */
  lastSelectedSizePrice: 0,
  /* selectedSize stores the currently selected item size, useful for multiple operations. */
  selectedSize: "",
  /* category contains the type of which the item falls under, used to fetch item when the user wants to update it from
    Cart page. */
  category: "",
  /* suggestionsBill stores the drink "called suggestions" with their count and total price, useful for calculations. */
  suggestionsBill: [],
  /* total stores the whole purchase order bill including VAT (fees are added to the whole purchase order). */
  total: 0,
  /* formOrder holds all the user's selected options in an item, needed before final data process to be stored. */
  formOrder: null,
  // selected: false,
  /* orders stores complete info about all user's purchased items after the final data process. */
  orders: [],
  /* previousOptions stores the options that the user selected in an ordered item, useful for when the user wants to
    make changes. */
  previousOptions: null,
  /* itemSizes holds the sizes available for the selected menu item. */
  itemSizes: null,
  /* itemIngredients holds the ingredients available for the selected menu item. */
  itemIngredients: null,
  /* itemSuggestions holds the suggestions "drinks" available for the selected menu item. */
  itemSuggestions: null,
  /* itemInstructions holds the special instructions for the selected item. */
  itemInstructions: "",
  /* optionsRetrieved specifies whether or not the options has been filled with data, useful for conditional
    rendering in Content Order. */
  optionsRetrieved: false,
  /* fees stores the fees designated by Pizza Service to be added to the final total, meanwhile subtotal holds the
    purchase order value without any extras. */
  fees: [
    { title: "Subtotal", price: 0 },
    { title: "Delivery Fee", price: 0 },
    // {title: "Service Fee", price: 5},
    { title: "Including VAT", price: 0 },
  ],
  // Drinks
  drinksData: [],
  isDrinksAdded: false,
  drinksStatus: "idle",
  globalDrinks: [],
  /* isPlacingOrder is a flag used to determine if a PlaceOrder request is sent */
  isPlacingOrder: false,
  /* taxPercentage holds the VAT percentage used to calculate the bill */
  taxPercentage: 0,
  /* deliveryFee holds the price of order delivery, it is set to 0 (free) if order method is NOT delivery */
  deliveryFee: 0,
  /* orderPlaced is a flag determining if order changes (if made) are saved */
  orderPlaced: true,
  /* isGettingOrder is a flag determining if the user's order is being fetched from the server */
  isGettingOrder: false,
  /* valuesRef stores the values of the items choices selected or previously selected by the user
    it stores them so they don't get lost */
  valuesRef: [],
  /* checkedItemsRef accompanies valuesRef but instead of storing a value, it stores unique identifiers for
    those choices to mark whether they're selected or not */
  checkedItemsRef: [],
  itemId: null,
  /* is a flag for when an item count is changed in the order that exists in OrderPage, helps determining
    if a POST request with the changes should be sent or not */
  orderCountChanged: false,
  // Specific time to deliver the order
  delivered_at: "",
  isDeliveredAt: false,
  specificTimeDelivered: null,
  couponValue: 0,
  couponData: {},
  isCouponApplied: localStorage.getItem("couponApplied")
    ? localStorage.getItem("couponApplied")
    : false,
  beforeCoupon: {
    subtotal: 0,
    total: 0,
    fees1: 0,
  },
  estimated_delivery_time: null,
  final_total: 0,
};

/* getItemOptions is used in assocation with getItemChoices to format and organize item's data */
export const getItemOptions = createAsyncThunk(
  "order/getItemOptions",
  async ({ item, itemOptions, states }) => {
    const options = JSON.parse(JSON.stringify(itemOptions));
    return {
      id: item.id,
      options: options,
      states: states,
    };
  }
);

// getDrinks
export const getDrinks = createAsyncThunk(
  "order/getDrinks",
  async (_, { getState, rejectWithValue }) => {
    const lang = getState().lang.lang;
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}drinks`, {
        headers: {
          "X-localization": lang,
        },
      });
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/* prepareBill calculates the bill both with (total) and without VAT (subtotal) */
const prepareBill = (orders, deliveryFee, taxPercentage) => {
  let subtotal = 0;

  orders.forEach(({ totalBill }) => {
    subtotal += Number(totalBill);
  });

  let includingVat = Number(subtotal).toFixed(2);
  let total = Number(includingVat) + Number(deliveryFee);
  return { subtotal, total };
};

/* placeOrder is used to send a request to the backend to store the order of the user with any update to it,
with all its necessary data */
export const placeOrder = createAsyncThunk(
  "order/placeOrder",
  async (inCheckout, { getState, dispatch, rejectWithValue }) => {
    const userAddress = getState().restaurantsDetails.userAddress;
    const orders = getState().order.orders;
    const fees = getState().order.fees;
    const taxPercentage = getState().order.taxPercentage;
    const { subtotal, total } = prepareBill(
      orders,
      fees[1].price,
      taxPercentage
    );
    const [lat, long] = userAddress.coordinates.split(",");

    let finalTotal = 0;
    getState().order.drinksData.length > 0 &&
      getState().order.drinksData.forEach(({ price }) => {
        finalTotal += price;
      });

    let data = {
      restaurant_id: getState().restaurantsDetails.selectedRestaurantId
        ? getState().restaurantsDetails.selectedRestaurantId
        : localStorage.getItem("selectedRestaurantId"),
      sub_total: subtotal,
      total:
        getState().order.drinksData.length > 0 ? total + finalTotal : total,
      final_total: getState().order.isCouponApplied
        ? getState().order.final_total
        : total,
      delivery_type_name_en:
        getState().showModal.selectedOrderMethodId.toLowerCase(),
      lat: lat,
      long: long,
      location: `${
        userAddress?.apartment ? userAddress?.apartment + ", " : ""
      }${userAddress.address}`,
      deliverey_at: getState().order.isDeliveredAt
        ? getState().order.delivered_at
        : null,
      coupon_id: getState().coupon?.couponData?.id,
      order_items: [],
    };

    let itemsCount = 0;
    let orderItems = orders.map(
      ({
        id,
        item_id,
        item_name,
        category_name,
        price_per_item,
        quantity,
        image,
        size,
        special_instructions,
        sub_items,
        subItemsCount,
      }) => {
        itemsCount += subItemsCount;
        if (special_instructions) {
          return {
            item_id: String(item_id),
            item_name,
            category_name,
            price_per_item,
            quantity,
            image,
            size,
            special_instructions,
            sub_items,
          };
        } else {
          return {
            item_id: String(item_id),
            item_name,
            category_name,
            price_per_item,
            quantity,
            image,
            size,
            sub_items,
          };
        }
      }
    );
    if (getState().order.drinksData) {
      getState().order.drinksData?.map((drink) => {
        orderItems.push({
          item_id: `drink${drink?.id}`,
          item_name: drink?.title,
          category_name: "drinks",
          price_per_item: drink?.price,
          quantity: drink?.count,
          image: drink?.imageSrc,
          size: "normal",
          sub_items: [
            {
              sub_item_name: drink?.title,
              quantity: 1,
              price_per_item: 0,
            },
          ],
        });
        return null;
      });
    }

    itemsCount += orderItems.length;

    data.order_items = orderItems;
    data.order_items_count = itemsCount;

    const token = JSON.parse(localStorage.getItem("user")).token;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}restaurants/order`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch(calculateTotal());
        dispatch(hideModal());
        dispatch(setValuesRef([]));
        dispatch(setCheckedItemsRef([]));
        if (getState().order.drinksData.length > 0) {
          dispatch(setIsDrinksAdded(true));
        }
        if (inCheckout) {
          dispatch(setInCheckout(true));
        } else {
          dispatch(changeSelectedItem());
          dispatch(setSelectedItemId(""));
        }
      }

      return {
        inCheckout: inCheckout,
      };
    } catch (error) {
      dispatch(resetOrder());
      return rejectWithValue(error.response.data);
    }
  }
);

/* getOrder dispatches a request to the backend to retrieve the user's order */
export const getOrder = createAsyncThunk(
  "order/getOrder",
  async (_, { dispatch, rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("user")).token;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}user/openOrder`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        if (response?.data?.data) {
          const orderMethod =
            response?.data?.data?.additional_order_details?.delivery_type;
          const userAddress = {
            success: true,
            address: response?.data?.data?.additional_order_details?.location,
            coordinates: `${response?.data?.data?.lat},${response?.data?.data?.long}`,
          };

          dispatch(
            setSelectedRestaurantId({
              id:
                response?.data?.data?.order_items?.length > 0
                  ? response?.data?.data?.restaurant_id
                  : "",
              nearby: orderMethod.toLowerCase() === "delivery",
            })
          );

          dispatch(setUserAddress(userAddress));
          dispatch(setUserTime(response?.data?.data?.estimated_delivery_time));
          if (response?.data?.data?.order_items?.length !== 0) {
            dispatch(
              setSelectedOrderMethod(
                orderMethod.slice(0, 1).toUpperCase() + orderMethod.slice(1)
              )
            );
          }
        }
      }
      return response?.data?.data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

// here we handle the formatting of "fetched" item choices and compare it to the defaults for display
// we search for the item unique id (we need to add that with each item we push to an order perhaps...)
// here we need to fetch the saved menu... so make this an asyncThunk.
// we search for the item in menu based on its id just to get its default choices.
export const getUserChoices = createAsyncThunk(
  "order/getUserChoices",
  async (selectedItem, { getState }) => {
    const menu = getState().menuItems.menu;
    return {
      selectedItem: selectedItem,
      menu: menu,
    };
  }
);

/* getSettings retrieves necessary information like VAT and estimated delivery time */
export const getSettings = createAsyncThunk(
  "order/getSettings",
  async (_, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}settings`);
      return {
        response: response.data.data[0],
        orderMethod: getState().showModal.selectedOrderMethodId,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    /* calculateOptionsBill makes calculations for user's choices on the extras that come with each item size,
        plus the ingredients, taking into consideration state.orderCount for correct bill calculation.
        action.payload hold an action type to perform correct actions, plus the value of the option which holds the name
        and the price of that option separated with underscores. */
    calculateOptionsBill: (state, action) => {
      const price =
        Number(action.payload.value.split("_")[1]) * state.orderCount;
      let tempLastSelectedSizePrice =
        state.lastSelectedSizePrice * state.orderCount;
      let tempBill = state.bill;
      switch (action.payload.type) {
        case "size":
          if (tempLastSelectedSizePrice !== price) {
            tempBill += price - tempLastSelectedSizePrice;
            state.lastSelectedSizePrice = price;
            state.selectedSize = action.payload.value.split("_")[0];
          }
          break;
        case "add":
          tempBill += price;
          break;
        case "remove":
          tempBill -= price;
          break;
        default:
          break;
      }
      state.bill = tempBill;
      // state.billWithVat = tempBill + (tempBill * state.taxPercentage / 100); // activate this when API is ready
    },

    /* calculateSuggestionsBill calculates the bill of the drinks "suggestions" and update the bill accordingly,
        along with updating state.previousOptions for correct display.
        action.payload holds the name, the id, total (price * count), and count of the drink in question. */
    calculateSuggestionsBill: (state, action) => {
      let lastTotal = 0;
      if (state.suggestionsBill.length) {
        state.suggestionsBill.forEach(({ total }) => {
          lastTotal += total;
        });
      }
      const { name, total, count } = action.payload;
      let suggestionIndex = state.suggestionsBill.findIndex(
        (suggestion) => suggestion.name === name
      );
      let tempBill = state.bill - lastTotal;
      if (suggestionIndex >= 0) {
        state.suggestionsBill[suggestionIndex].total = total;
      } else {
        state.suggestionsBill.push({ name: name, total: total });
      }
      state.suggestionsBill.forEach(({ total }) => {
        tempBill += total;
      });
      state.bill = tempBill;
      // state.billWithVat = tempBill + (tempBill * state.taxPercentage / 100); // activate this when API is ready
      suggestionIndex = state.previousOptions.suggestions.findIndex(
        ({ title }) => title === name
      );
      state.previousOptions.suggestions[suggestionIndex].count = count;
      // modify itemSuggestions ?, though it's working //
    },

    /* handleOrderCount updates the state.orderCount with the new count provided in action.payload.orderCount
        and updates the bill accordingly. */
    handleOrderCount: (state, action) => {
      let tempTotal = 0;
      state.suggestionsBill.forEach(({ total }) => (tempTotal += total));
      let tempBill = state.bill - tempTotal;
      tempBill /= state.orderCount;
      tempBill *= action.payload.orderCount;
      state.orderCount = action.payload.orderCount;
      state.bill = tempBill + tempTotal;
      // state.billWithVat = state.bill + (state.bill * state.taxPercentage / 100);
    },

    /* changeSelectedItem resets the states regarding an item when it's changed or deselected. */
    changeSelectedItem: (state) => {
      state.bill = 0;
      // state.billWithVat = 0;
      state.orderCount = 1;
      state.lastSelectedSizePrice = 0;
      state.suggestionsBill = [];
      state.selectedSize = "";
      state.previousOptions = null;
      state.itemSizes = null;
      state.itemIngredients = null;
      state.itemSuggestions = null;
      state.itemInstructions = null;
      state.optionsRetrieved = false;
    },

    /* multiplyOrder perform the corrent increment or decrement in the orderCount and bill in Cart page.
        action.payload has the type of the action, orderId, and orderCount.
        if action.payload.type value is 'remove', the order is removed from state.orders and state.total is updated
        accordingly. */
    multiplyOrder: (state, action) => {
      let orders = JSON.parse(JSON.stringify([...state.orders]));
      let tempTotal = 0;
      if (action.payload.type === "remove") {
        orders = orders.filter(
          ({ orderId }) => orderId !== action.payload.orderId
        );
      } else {
        const orderIndex = orders.findIndex(
          ({ orderId }) => orderId === action.payload.orderId
        );
        if (orders[orderIndex].suggestionsBill) {
          orders[orderIndex]?.suggestionsBill.forEach(
            ({ total }) => (tempTotal += Number(total))
          );
        }

        let tempBill = Number(orders[orderIndex].totalBill) - tempTotal;

        tempBill /= orders[orderIndex].orderCount;

        tempBill *= action.payload.orderCount;

        orders[orderIndex].orderCount = action.payload.orderCount;
        orders[orderIndex].quantity = action.payload.orderCount;
        orders[orderIndex].totalBill = tempBill + tempTotal;
        // orders[orderIndex].totalBillWithVat = (tempBill + tempTotal) + ((tempBill + tempTotal) * state.taxPercentage / 100); // activate this when API is ready
        orders[orderIndex].item_with_sub_items_price = tempBill + tempTotal;
        // orders[orderIndex].price_with_vat = (tempBill + tempTotal) + ((tempBill + tempTotal) * state.taxPercentage / 100); // activate this when API is ready
      }
      state.orders = JSON.parse(JSON.stringify(orders));

      let finalTotal = 0;
      state.orders.forEach(
        ({ totalBill }) => (finalTotal += Number(totalBill))
      );

      state.drinksData.length > 0 &&
        state.drinksData.forEach(({ price }) => {
          finalTotal += price;
        });

      const subtotal = finalTotal;
      // let includingVat = 0;
      state.fees[state.fees.length - 1].price = Number(subtotal.toFixed(2));
      // state.fees[state.fees.length - 1].price = Number(
      //   (subtotal + (subtotal * Number(state.taxPercentage)) / 100).toFixed(2)
      // );

      // orders.forEach(({totalBillWithVat}) => includingVat += totalBillWithVat); // activate this when API is ready
      // state.fees[state.fees.length-1].price = includingVat;
      let fees = 0;
      state.fees.forEach(({ price }, index) =>
        typeof price === "string" || index === 0 ? (fees += 0) : (fees += price)
      );

      state.total = subtotal <= 0 ? 0 : fees;

      state.fees[0].price = subtotal < 0 ? 0 : subtotal;

      state.orderCountChanged = true;
    },

    /* calculateGlobalDrinks calculates the bill of the drinks "suggestions" in Cart page and updates both the total
        and subtotal correctly, without incorrectly affecting the orders already listed.
        action.payload holds the drink id, total (its price * its count), and its count. */
    calculateGlobalDrinks: (state, action) => {
      let lastTotal = 0;
      state.globalDrinks.forEach(({ drinkTotal }) => {
        lastTotal += drinkTotal;
      });

      const { id, drinkTotal, count, type } = action.payload;

      const globalDrinkIndex = state.globalDrinks.findIndex(
        (globalDrink) => globalDrink.id === id
      );

      let tempTotal = state.total - lastTotal;

      state.globalDrinks[globalDrinkIndex].drinkTotal = drinkTotal;
      state.globalDrinks[globalDrinkIndex].count = count;

      state.globalDrinks.forEach(({ drinkTotal }) => {
        tempTotal += drinkTotal;
      });

      if (type === "add") {
        const tempDrink = state.drinksData
          ? state.drinksData?.filter((el) => el.id === id)
          : [];

        if (tempDrink?.length === 0) {
          state.drinksData.push({
            id: state.globalDrinks[globalDrinkIndex].id,
            title: state.globalDrinks[globalDrinkIndex].title,
            price: state.globalDrinks[globalDrinkIndex].price,
            imageSrc: state.globalDrinks[globalDrinkIndex].imageSrc,
            imageAlt: state.globalDrinks[globalDrinkIndex].title,
            count: state.globalDrinks[globalDrinkIndex].count,
          });
        } else {
          state.drinksData = state.drinksData.filter((el) => el.id !== id);
          state.drinksData.push({
            id: state.globalDrinks[globalDrinkIndex].id,
            title: state.globalDrinks[globalDrinkIndex].title,
            price: state.globalDrinks[globalDrinkIndex].price,
            imageSrc: state.globalDrinks[globalDrinkIndex].imageSrc,
            imageAlt: state.globalDrinks[globalDrinkIndex].title,
            count: state.globalDrinks[globalDrinkIndex].count,
          });
        }
      }

      if (type === "remove") {
        const tempDrink = state.drinksData
          ? state.drinksData?.filter((el) => el.id === id)
          : [];

        if (tempDrink?.length > 0) {
          state.drinksData = state.drinksData.filter((el) => el.id !== id);
          state.globalDrinks[globalDrinkIndex].count > 0 &&
            state.drinksData.push({
              id: state.globalDrinks[globalDrinkIndex].id,
              title: state.globalDrinks[globalDrinkIndex].title,
              price: state.globalDrinks[globalDrinkIndex].price,
              imageSrc: state.globalDrinks[globalDrinkIndex].imageSrc,
              imageAlt: state.globalDrinks[globalDrinkIndex].title,
              count: state.globalDrinks[globalDrinkIndex].count,
            });
        }
      }

      let fees = 0;
      // state.fees.forEach(({ price }, index) =>
      //   typeof price === "string" || index === 0 ? (fees += 0) : (fees += price)
      // );
      const subtotal = tempTotal - fees;

      state.total = subtotal <= 0 ? 0 : tempTotal;
      state.fees[0].price = subtotal < 0 ? 0 : subtotal;
    },
    // this section needs to be modified to handle recent changes with total and VAT.

    /* setForm takes all user's options after adding an order to cart, updates the necessary states
        regarding the info of the order, the options, the bill, and stores them all correctly to be sent later,
        reviewed, or updated by the user.
        action.payload holds all the form fields in ContentOrder section of the Restaurant page. */
    // new functionality
    setForm: (state, action) => {
      let formOrder = action.payload;
      let order = {
        id: state.itemId,
        item_id: formOrder.orderId,
        item_name: formOrder.orderName,
        category_name: state.category,
        quantity: Number(formOrder.orderCount),
        image: formOrder.orderImage,
        special_instructions: formOrder.specialInstructions,
        totalBill: formOrder.totalBill,
        // totalBillWithVat: formOrder.totalBill + (formOrder.totalBill * state.taxPercentage / 100), // activate this when API is ready
      };

      const [size, price] = formOrder.orderSize.split("_");
      order = { ...order, price_per_item: Number(price), size: size };
      const choices = formOrder.orderExtras.split(",").map((extra) => {
        if (extra) {
          const [extraName, extraPrice] = extra.split("_");
          // const variation = state.itemSizes.find(({label}) => label === size);
          // variation.extras.forEach(({id}, index) => {
          //     if (id === extraId) {
          //         state.itemSizes.extras[index].selected = true;
          //         // state
          //     }
          // })
          return {
            sub_item_name: extraName,
            price_per_item: Number(extraPrice),
            quantity: 1,
          };
        }
      });
      order.sub_items = choices;
      order.subItemsCount = choices.length;

      order = { ...order, ...formOrder };
      const tempOrders = JSON.parse(JSON.stringify(state.orders));
      // check this if it's trying to update the item instead of adding separately.
      const itemIndex = tempOrders.findIndex(
        (tempOrder) => tempOrder.id === order.id
      );
      if (itemIndex !== -1) {
        tempOrders[itemIndex] = order;
      } else {
        tempOrders.push(order);
      }
      state.orders = JSON.parse(JSON.stringify(tempOrders));
    },

    /* resetOrder resets all the data of the user's cart after a successful checkout. */
    resetOrder: (state) => {
      state.itemId = null;
      state.bill = 0;
      // state.billWithVat = 0;
      state.total = 0;
      state.fees[0].price = 0;
      state.formOrder = null;
      state.orderCount = 1;
      state.lastSelectedSizePrice = 0;
      state.selectedSize = "";
      state.itemIngredients = null;
      state.itemInstructions = "";
      state.itemSuggestions = null;
      state.previousOptions = null;
      state.optionsRetrieved = false;
      state.suggestionsBill = [];
      state.selected = false;
      state.orders = [];
      state.isDrinksAdded = false;
      state.drinksData = [];
      state.valuesRef = [];
      state.checkedItemsRef = [];
    },

    /* setCategory stores the type of an item, where action.payload holds that piece of info. */
    setCategory: (state, action) => {
      state.category = action.payload;
    },

    /* calculateTotal calculates the total bill of the seleced items (for each item in the order) */
    calculateTotal: (state) => {
      let tempTotal = 0;
      // let tempTotalWithVat = 0; // activate this when API is ready
      state.orders.forEach(({ totalBill }) => {
        tempTotal += Number(totalBill);
      }); // use the one below when API is ready
      // state.orders.forEach(({totalBill, totalBillWithVat}) => {
      //     tempTotal += Number(totalBill);
      //     tempTotalWithVat += Number(totalBillWithVat);
      // });
      state.drinksData.length > 0 &&
        state.drinksData.forEach(({ price }) => {
          tempTotal += price;
        });
      // state.globalDrinks.forEach(({ total }) => (tempTotal += Number(total)));
      const subtotal = tempTotal;
      // state.fees[state.fees.length - 1].price = Number(
      //   (subtotal + (subtotal * state.taxPercentage) / 100).toFixed(2)
      // );
      state.fees[state.fees.length - 1].price = Number(subtotal.toFixed(2));
      // use the one below when API is ready
      // state.fees[state.fees.length-1].price = tempTotalWithVat;
      let fees = 0;
      state.fees.forEach(({ price }, index) =>
        typeof price === "string" || index === 0 ? (fees += 0) : (fees += price)
      );

      state.total = subtotal <= 0 ? 0 : fees;
      state.fees[0].price = subtotal < 0 ? 0 : subtotal;
    },

    /* setDeliveryInFees sets the delivery price in state.fees according to the order method
        if it's delivery then it's set with the value, else it's set to 0 (free) */
    setDeliveryInFees: (state, action) => {
      state.fees[1].price =
        action.payload.toLowerCase() === "delivery" ? state.deliveryFee : 0;
    },

    setOrderPlaced: (state, action) => {
      state.orderPlaced = action.payload;
    },

    setValuesRef: (state, action) => {
      state.valuesRef = [...action.payload];
    },

    setCheckedItemsRef: (state, action) => {
      state.checkedItemsRef = [...action.payload];
    },

    setItemId: (state, action) => {
      state.itemId = action.payload;
    },

    setIsDrinksAdded: (state, action) => {
      state.isDrinksAdded = action.payload;
    },

    setDeliveredAt: (state, action) => {
      state.delivered_at = action.payload;
    },
    setIsDeliveredAt: (state, action) => {
      state.isDeliveredAt = action.payload;
    },

    setIsCouponApplied: (state, action) => {
      state.isCouponApplied = action.payload;
    },

    applyCoupon: (state, action) => {
      if (action.payload?.status) {
        localStorage.setItem("couponApplied", true);
        if (action.payload?.type == "percentage") {
          const couponVal = action.payload.value / 100;

          state.beforeCoupon = {
            subtotal: state.fees[0].price,
            total: state.total,
            fees1: state.fees[1].price,
          };

          localStorage.setItem(
            "beforeCoupon",
            JSON.stringify(state.beforeCoupon)
          );

          const newArr = Array.from(state.orders);
          const newOrdersArr = newArr?.map((el) => ({
            ...el,
            item_with_sub_items_price:
              el?.item_with_sub_items_price -
              el?.item_with_sub_items_price * couponVal,
            totalBill: el?.totalBill - el?.totalBill * couponVal,
          }));
          state.orders = newOrdersArr;
          state.final_total = state.total - state.total * couponVal;
          state.fees[0].price =
            state.fees[0].price - state.fees[0].price * couponVal;
          state.fees[1].price =
            state.fees[1].price - state.fees[1].price * couponVal;
          state.deliveryFee = state.deliveryFee - state.deliveryFee * couponVal;

          state.isCouponApplied = true;
          state.couponValue = couponVal;
        } else {
          const couponVal = action.payload.value;

          state.beforeCoupon = {
            subtotal: state.fees[0].price,
            total: state.total,
            fees1: state.fees[1].price,
          };

          const newArr = Array.from(state.orders);
          const newOrdersArr = newArr?.map((el) => ({
            ...el,
            item_with_sub_items_price:
              el?.item_with_sub_items_price -
              el?.item_with_sub_items_price -
              couponVal,
            totalBill: el?.totalBill - el?.totalBill - couponVal,
          }));
          state.orders = newOrdersArr;
          state.total = Math.max(0, state.total - couponVal);
          state.final_total = Math.max(0, state.total - couponVal);
          state.fees[0].price = Math.max(0, state.fees[0].price - couponVal);
          state.fees[1].price = Math.max(1, state.fees[1].price - couponVal);

          state.isCouponApplied = true;
          state.couponValue = couponVal;
        }
      }
    },
    removeCoupon: (state) => {
      if (state.isCouponApplied) {
        localStorage.setItem("couponApplied", false);
        state.isCouponApplied = false;
        state.total = state.beforeCoupon?.total;
        state.fees[0].price = state.beforeCoupon?.subtotal;
      }
    },
  },
  extraReducers: (builder) => {
    /* this case is needed to correctly fetch an item's options, either the default if they're not present in state.orders
        or the ones already existing in state.orders that holds all of the user's choices. */
    builder
      .addCase(getItemOptions.fulfilled, (state, action) => {
        const id = action.payload.id;
        const options = action.payload.options;

        state.selectedSize = action.payload.states?.size
          ? action.payload.states.size
          : "";
        state.bill = action.payload.states?.bill
          ? action.payload.states.bill
          : 0;
        state.orderCount = action.payload.states?.count
          ? action.payload.states.count
          : 1;
        state.previousOptions = options;
        state.itemSizes = state.previousOptions.sizes;
        state.itemSuggestions = state.previousOptions.suggestions;
        state.itemInstructions = state.previousOptions.instructions;
        if (!state.optionsRetrieved) {
          state.optionsRetrieved = true;
        }
        state.orderPlaced = false;
      })

      .addCase(placeOrder.fulfilled, (state, action) => {
        state.isPlacingOrder = false;
        if (!action.payload.inCheckout) {
          if (action.payload.inCheckout === undefined) {
            toast.success(i18n.t("Your order has been added to cart"));
            state.orders = [];
          }
        }
        state.orderPlaced = true;
        state.orderCountChanged = false;
      })
      .addCase(placeOrder.pending, (state) => {
        state.isPlacingOrder = true;
        state.orderPlaced = false;
      })
      .addCase(placeOrder.rejected, (state, action) => {
        state.isPlacingOrder = false;
        action.payload?.message?.map((m) => {
          toast.error(i18n.t(m));
          return null;
        });
        state.orderPlaced = false;
      })

      .addCase(getOrder.fulfilled, (state, action) => {
        if (action.payload) {
          state.fees[0].price = action.payload.sub_total;
          state.fees[state.fees.length - 1].price =
            action.payload.additional_order_details.delivery_type === "delivery"
              ? action.payload.total - state.deliveryFee
              : action.payload.total;
          state.total = action.payload.total;
          state.final_total = action.payload.total;
          // state.isCouponApplied = Boolean(action.payload.coupon)
          // localStorage.setItem('couponApplied', state.isCouponApplied)
          const formattedItems = JSON.parse(
            JSON.stringify(action.payload.order_items)
          ).map((item) => {
            return {
              ...item,
              special_instructions: item.special_instructions
                ? item.special_instructions
                : "",
              orderId: item.id,
              orderName: item.item_name,
              orderCount: item.quantity,
              orderSize: item.size,
              orderImage: item.image,
              specialInstructions: item.special_instructions
                ? item.special_instructions
                : "",
              totalBill: item.item_with_sub_items_price,
              orderExtras: item.sub_items,
              subItemsCount: item.sub_items.length, // replace this with the one below when API is ready
            };
          });
          state.orders = JSON.parse(JSON.stringify(formattedItems));
        } else {
          state.orders = [];
        }
        state.isGettingOrder = false;
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.isGettingOrder = false;
      })
      .addCase(getOrder.pending, (state) => {
        state.isGettingOrder = true;
      })
      .addCase(getUserChoices.fulfilled, (state, action) => {
        const options = {};
        const item = state.orders.find(
          ({ orderId }) => orderId === action.payload.selectedItem.id
        );
        if (item !== undefined) {
          state.previousOptions = { ...item.orderOptions };
          state.bill = item.totalBill;
          state.orderCount = item.orderCount;
        } else {
          state.previousOptions = options;
        }
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.fees[1].price =
          action.payload.orderMethod.toLowerCase() === "delivery"
            ? Number(action.payload.response.delivery_price)
            : 0;
        state.deliveryFee = Number(action.payload.response.delivery_price);
        state.taxPercentage = Number(action.payload.response.vat);
        state.minBill = Number(action.payload.response.minimum_order_value);
      })
      .addCase(getDrinks.pending, (state) => {
        state.drinksStatus = "loading";
      })
      .addCase(getDrinks.fulfilled, (state, action) => {
        state.drinksStatus = "succeeded";

        state.globalDrinks = action.payload?.map((item, index) => {
          return {
            id: item?.id,
            imageSrc: `${item?.main_image}`,
            imageAlt: item?.title,
            price: item?.price,
            price_after_vat: item?.price,
            title: item?.title,
            drinkTotal: 0,
            // total: 0,
            count: 0,
          };
        });
      })
      .addCase(getDrinks.rejected, (state, action) => {
        state.drinksStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  calculateOptionsBill,
  calculateSuggestionsBill,
  handleOrderCount,
  changeSelectedItem,
  multiplyOrder,
  calculateGlobalDrinks,
  setForm,
  resetOrder,
  setCategory,
  calculateTotal,
  setDeliveryInFees,
  setOrderPlaced,
  setValuesRef,
  setCheckedItemsRef,
  setItemId,
  setIsDrinksAdded,
  setDeliveredAt,
  setIsDeliveredAt,
  applyCoupon,
  removeCoupon,
  setIsCouponApplied,
} = orderSlice.actions;

export default orderSlice.reducer;
